import React, { Suspense, Component } from "react"
import get from "lodash/get"
import { BrowserRouter } from "react-router-dom"
import { connect } from "react-redux"
import axios from "axios"
import { QueryClientProvider, QueryClient } from "react-query"
import { withLDProvider } from "launchdarkly-react-client-sdk"
import { useDistributorPlatform } from "_Infinity/DistributorPlatform/Hooks/useDistributorPlatform"
import App from "views/app/App"
import AppTitle from "views/app/AppTitle"
import {
  loginWithToken,
  validateToken,
  refreshToken,
  logout,
  __logout,
} from "actions/auth"
import { loadCategories, loadAllCategories } from "actions/categories"
import { loadNetworks } from "actions/networks"
import { loadMspOwner } from "actions/organization"
import { loadUserAgents } from "actions/userAgents"
import { loadLanProxies } from "actions/lanProxies"
import { loadPolicies } from "actions/filtering"
import { loadFilteringSchedules } from "actions/filteringSchedules"
import { showNotificationWithTTL } from "actions/notifications"
import { loadBlockPages } from "actions/blockPages"
import { loadUsers } from "actions/users"
import { loadInitBilling } from "actions/billing"
import queryString from "query-string"
import callAPI from "callAPI"

import { isTwoFAEnforcement } from "utils/auth"
import { load, sendUTMParamsToAPI } from "utils/analytics"

import { Spinner } from "_Infinity/Core/Components/Spinner"

import AppRoutes from "views/app/AppRoutes"

import { KnobsProvider } from "_Infinity/Knobs/Providers/KnobsProvider"
import { BulkNetworksProvider } from "_Infinity/BulkNetworks/Providers/BulkNetworksProvider"
import { SessionProvider } from "_v2/Modules/Auth"

import { DISTPLATFORM_TEMPLATES } from "_Infinity/DistributorPlatform/constants"

import { PartnerProgramProvider } from "components/PartnerProgramContext"

const queryClient = new QueryClient({})

class AppContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      reportedDomains: [],
    }

    if (process.env.REACT_APP_SEGMENT_ID) {
      load()
    }
  }

  // TODO: we need to rethink this logic.
  //
  // 1. It's hard to understand its intent.
  // 2. It's fragile, we can't rely on URL segments to set the orgId.
  //
  updateOrgIdBasedOnUrl = () => {
    if (localStorage.getItem("owned_msp_id")) {
      return
    }

    let orgId = window.location.pathname.split("/")[2] || null

    if (orgId === null) {
      return
    }

    if (!parseInt(orgId, 10)) {
      return
    }

    localStorage.setItem("orgId", orgId)
  }

  async componentDidMount() {
    localStorage.removeItem("orgChanged")

    const { loginWithToken, validateToken } = this.props
    const { email, code, last } = queryString.parse(window.location.search)

    let id_token = null

    const platform =
      DISTPLATFORM_TEMPLATES[localStorage.getItem("distributor")] ??
      DISTPLATFORM_TEMPLATES.default

    const iframe = window.parent !== window

    if (code) {
      try {
        id_token = await axios({
          method: "post",
          url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
          data: {
            grant_type: "authorization_code",
            client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
            redirect_uri: window.location.origin,
            code,
            scope: "openid",
          },
        }).then(({ data }) => {
          const { id_token } = data
          this.props.logout()

          if (!platform.shouldBypassBuiltInSupportBot && !iframe) {
            localStorage.setItem("zendeskEnabled", true)
          }

          return id_token
        })
      } catch (error) {
        console.error(error)
      }
    }

    this.updateOrgIdBasedOnUrl()

    try {
      if (email) {
        localStorage.setItem("email_from_site", email)
      }

      if (id_token) {
        const resLogin = await loginWithToken(
          id_token,
          /* resetOrgDetails: */ true,
          /* distributorPlatform */ false,
          last,
        )

        localStorage.setItem("impersonation", "true")

        if (!resLogin?.type) {
          window.location.href = window.location.origin
        }
      } else {
        const token = await validateToken()
        if (token) {
          await loginWithToken(token, false, false, last)
        }
      }
    } catch (error) {
      console.error(error)
    }

    function dispatchRefreshToken() {
      refreshToken().catch(() => {
        const iframe = window.parent !== window

        if (iframe) {
          __logout()

          window.location.href = `/?distplatform=${localStorage.getItem(
            "distributor",
          )}`
        }
      })
    }

    dispatchRefreshToken()

    this.refreshTokenInterval = setInterval(dispatchRefreshToken, 30 * 1000)
  }

  componentWillUnmount() {
    clearInterval(this.refreshTokenInterval)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If it is a user with no policies, and NOT a member of an MSP sub-org
    // then tag as ‘new-user’
    if (
      this.props.policies.status === "fetching" &&
      nextProps.policies.status === "succeeded" &&
      (!nextProps.policies.data || !nextProps.policies.data.length) &&
      !localStorage.getItem("organization.isMSPSubAccount")
    ) {
      window.hj("trigger", "new-user")
      window.hj("tagRecording", [get(nextProps, "auth.data.name", "no-name")])
    }
  }

  componentDidUpdate() {
    // Let's send it as soon as possible. It'll bail out if the user is not
    // logged in or if the analytics services are still loading.
    sendUTMParamsToAPI()

    const {
      categories,
      allCategories,
      networks,
      organizations,
      policies,
      blockPages,
      filteringSchedules,
      loadCategories,
      loadAllCategories,
      loadNetworks,
      loadPolicies,
      loadBlockPages,
      loadMspOwner,
      loadFilteringSchedules,
      showNotificationWithTTL,
      userAgents,
      loadUserAgents,
      lanProxies,
      loadLanProxies,
      users,
      loadUsers,
      initBilling,
      loadInitBilling,
    } = this.props

    if (this.props.auth.status !== "loginSucceeded") {
      return
    }

    if (!categories.status) {
      loadCategories()
    }

    if (
      localStorage.getItem("organization.isMSP") &&
      localStorage.getItem("organization.isMSPSubAccount") &&
      !!organizations.data.length &&
      !organizations.mspStatus
    ) {
      loadMspOwner()
    }

    if (!allCategories.status) {
      loadAllCategories()
    }

    if (!networks.status) {
      loadNetworks()
    }

    if (!policies.status) {
      loadPolicies()
    }

    if (!blockPages.status) {
      loadBlockPages()
    }

    if (!filteringSchedules.status) {
      loadFilteringSchedules()
    }

    if (!userAgents.requestStatus) {
      loadUserAgents()
    }

    if (!lanProxies.requestStatus) {
      loadLanProxies()
    }

    if (!users.status) {
      loadUsers()
    }

    if (!initBilling?.status) {
      const iframe = window.parent !== window

      if (iframe && localStorage.getItem("distributor")) {
        // Do nothing
      } else {
        loadInitBilling()
      }
    }

    const parsedQS = queryString.parse(window.location.search)
    const domain = parsedQS && parsedQS.report_domain
    if (domain && this.state.reportedDomains.indexOf(domain) === -1) {
      this.setState(
        (prevState) => ({
          reportedDomains: [...prevState.reportedDomains, domain],
        }),
        () => {
          callAPI({
            method: "POST",
            path: `/support/email`,
            body: {
              organization_id: localStorage.getItem("orgId"),
              body_text: `A customer has reported a domain as being miscategorized. Details:<br /><br />
                domain:    ${domain}<br /><br />
                org name:  ${localStorage.getItem("orgName")}<br />
                org ID:    ${localStorage.getItem("orgId")}<br /><br />`,
              subject: `Domain miscategorized: ${domain}`,
              support: "false",
            },
          })
            .then(() => {
              showNotificationWithTTL(
                {
                  type: "success",
                  message: (
                    <span>
                      Thank you for your request, we will review{" "}
                      <strong>{domain}</strong> within 2 business days and
                      inform you when complete.
                    </span>
                  ),
                  // icon: 'check'
                },
                15,
              )

              this.props.history.push(window.location.pathname)
            })
            .catch(() => {
              showNotificationWithTTL(
                {
                  type: "danger",
                  message: (
                    <span>
                      Failed to request <strong>{domain}</strong> for review.{" "}
                      <a
                        href="#try-again"
                        style={{
                          color: "yellow",
                        }}
                        onClick={(event) => {
                          event.preventDefault()
                          window.location.reload()
                        }}
                      >
                        Try Again
                      </a>
                    </span>
                  ),
                  // icon: 'exclamation-circle'
                },
                15,
              )
            })
        },
      )
    }
  }

  isAuthorized(props) {
    return props.auth.status === "loginSucceeded"
  }

  shouldShowDNSServers(props) {
    return !props.policies.data.filter((policy) => policy.policy_ip_id).length
  }

  render() {
    return (
      <KnobsProvider
        knobs={[
          {
            id: "playground",
            type: "switch",
            group: "Misc",
            label: "Enable playground",
            defaultValue: false,
          },
        ]}
      >
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <PartnerProgramProvider>
              <BulkNetworksProvider>
                <SessionProvider
                  ready={this.props.auth.status === "loginSucceeded"}
                >
                  <AppTitle>
                    <App
                      {...this.props}
                      isLoading={
                        this.props.auth.status === "loginRequested" ||
                        this.props.initBilling?.status === "request"
                      }
                      isAuthorized={this.props.auth.status === "loginSucceeded"}
                      shouldShowDNSServers={this.shouldShowDNSServers(
                        this.props,
                      )}
                      isTwoFAEnforcement={isTwoFAEnforcement(this.props)}
                    >
                      <Suspense
                        fallback={
                          <div
                            style={{
                              padding: 20,
                            }}
                          >
                            <Spinner size={6} />
                          </div>
                        }
                      >
                        <AppRoutes {...this.props} />
                      </Suspense>
                    </App>
                  </AppTitle>
                </SessionProvider>
              </BulkNetworksProvider>
            </PartnerProgramProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </KnobsProvider>
    )
  }
}

function mapStateToProps({
  auth,
  categories,
  allCategories,
  networks,
  organizations,
  policies,
  blockPages,
  filteringSchedules,
  userAgents,
  lanProxies,
  users,
  initBilling,
}) {
  return {
    auth,
    categories,
    allCategories,
    networks,
    organizations,
    policies,
    blockPages,
    filteringSchedules,
    userAgents,
    lanProxies,
    users,
    initBilling,
  }
}

const mapDispatchToProps = {
  loginWithToken,
  validateToken,
  logout,
  loadCategories,
  loadAllCategories,
  loadNetworks,
  loadMspOwner,
  loadPolicies,
  loadBlockPages,
  loadUserAgents,
  loadLanProxies,
  showNotificationWithTTL,
  loadFilteringSchedules,
  loadUsers,
  loadInitBilling,
}

function AppContainerContainer(props) {
  const platform = useDistributorPlatform()

  return <AppContainer {...props} platform={platform} />
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
})(connect(mapStateToProps, mapDispatchToProps)(AppContainerContainer))
