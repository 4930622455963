import { getUserAndOrgData, getMspData } from "actions/auth"
import {
  userSchema,
  organizationSchema,
  subscriptionSchema,
  httpClient,
} from "_v2/Modules/Core"

function normalizeFeaturesFlags(featureFlags) {
  return {
    showDesktopRoamingClients: featureFlags.includes("desktop_rc"),
    showMobileRoamingClient: featureFlags.includes("mobile_rc"),
    showRelay: featureFlags.includes("relay"),
    showDNSSecSupport: featureFlags.includes("dnssec"),
    showActiveDirectory: featureFlags.includes("active_directory"),
    showReportRetention: featureFlags.includes("report_30_days")
      ? 30
      : featureFlags.includes("report_60_days")
      ? 60
      : featureFlags.includes("report_90_days")
      ? 90
      : false,
    showRawLogRetention: featureFlags.includes("log_3_days")
      ? ["last72Hours"]
      : featureFlags.includes("log_6_days")
      ? ["last72Hours", "last144Hours"]
      : featureFlags.includes("log_9_days")
      ? ["last72Hours", "last144Hours", "last216Hours"]
      : featureFlags.includes("log_90_days")
      ? ["last72Hours", "last144Hours", "last216Hours", "last2160Hours"]
      : false,
    showQueryLogExport: featureFlags.includes("query_log_export"),
    showAPIAccess: featureFlags.includes("api_access"),
    showChatSupport: featureFlags.includes("chat_support"),
    showPhoneSupport: featureFlags.includes("phone_support"),
    showAppAware: true,
    isLegacyEnterprise: featureFlags.includes("legacy_enterprise"),
    isLegacyEducation: featureFlags.includes("legacy_education"),
    isAppAwareBeta: featureFlags.includes("appaware_beta"),
    isUniversalListsBeta: featureFlags.includes("universal_lists_beta"),
  }
}

function getAccessControlList(plan, featureFlags) {
  switch (plan) {
    case "legacy":
      return {
        showChatSupport: featureFlags.showChatSupport,
        supportPhone: featureFlags.showPhoneSupport,
        supportRequestCallback: featureFlags.showPhoneSupport,
        isLegacyEnterprise: featureFlags.isLegacyEnterprise,
        forceLegacyEnterprise: featureFlags.isLegacyEnterprise,
        isLegacyEducation: featureFlags.isLegacyEducation,
        showAppAware: featureFlags.showAppAware,
      }

    case "basic":
      return {
        deploymentsRelays: featureFlags.showRelay,
        deploymentsRoamingClientsInstallMobile:
          featureFlags.showMobileRoamingClient,
        deploymentsCollections: featureFlags.showActiveDirectory,
        deploymentsSincTools: featureFlags.showActiveDirectory,
        toolsQueryLogCsvExport: featureFlags.showQueryLogExport,
        showChatSupport: featureFlags.showChatSupport,
        supportPhone: featureFlags.showPhoneSupport,
        supportRequestCallback: featureFlags.showPhoneSupport,
        hideLocalUsersQueryLog: true,
        hideColumnLocalUserNameQueryLog: true,
        isLegacyEnterprise: featureFlags.isLegacyEnterprise,
        isLegacyEducation: featureFlags.isLegacyEducation,
        showAppAware: featureFlags.showAppAware,
      }

    case "pro":
      return {
        deploymentsRoamingClientsInstallMobile:
          featureFlags.showMobileRoamingClient,
        supportPhone: featureFlags.showPhoneSupport,
        supportRequestCallback: featureFlags.showPhoneSupport,
        isLegacyEnterprise: featureFlags.isLegacyEnterprise,
        isLegacyEducation: featureFlags.isLegacyEducation,
        showAppAware: featureFlags.showAppAware,
      }

    case "guest_wifi":
      return {
        deploymentsRoamingClients: featureFlags.showDesktopRoamingClients,
        deploymentsRoamingClientsInstallMobile:
          featureFlags.showMobileRoamingClient,
        deploymentsCollections: featureFlags.showActiveDirectory,
        deploymentsSincTools: featureFlags.showActiveDirectory,
        showChatSupport: featureFlags.showChatSupport,
        supportPhone: featureFlags.showPhoneSupport,
        supportRequestCallback: featureFlags.showPhoneSupport,
        isLegacyEnterprise: featureFlags.isLegacyEnterprise,
        isLegacyEducation: featureFlags.isLegacyEducation,
        showAppAware: featureFlags.showAppAware,
      }

    case "enterprise":
      return {
        supportPhone: featureFlags.showPhoneSupport,
        supportRequestCallback: true,
        isLegacyEnterprise: featureFlags.isLegacyEnterprise,
        isLegacyEducation: featureFlags.isLegacyEducation,
        showAppAware: featureFlags.showAppAware,
      }

    case "msp_tier3":
      return {
        supportPhone: true,
        supportRequestCallback: true,
        isLegacyEnterprise: featureFlags.isLegacyEnterprise,
        isLegacyEducation: featureFlags.isLegacyEducation,
        showAppAware: featureFlags.showAppAware,
      }

    default:
      return {
        supportPhone: featureFlags.showPhoneSupport,
        isLegacyEnterprise: featureFlags.isLegacyEnterprise,
        isLegacyEducation: featureFlags.isLegacyEducation,
        showAppAware: featureFlags.showAppAware,
      }
  }
}

function getMaxRententionDays(plan, featureFlags) {
  if (featureFlags.showReportRetention) {
    return featureFlags.showReportRetention
  }

  switch (plan) {
    case "pro":
      return 60

    case "enterprise":
      return 90

    case "education":
      return 90

    default:
      return 30
  }
}

function getQuerylogOptions(plan, featureFlags) {
  if (featureFlags.showRawLogRetention) {
    return featureFlags.showRawLogRetention
  }

  switch (plan) {
    case "basic":
      return ["last72Hours"]

    case "education":
      return ["last72Hours", "last144Hours", "last216Hours"]

    case "pro":
      return ["last72Hours", "last144Hours"]

    case "enterprise":
      return ["last72Hours", "last144Hours", "last216Hours"]

    case "msp_tier1":
      return ["last72Hours", "last144Hours", "last216Hours"]

    case "msp_tier2":
      return ["last72Hours", "last144Hours", "last216Hours"]

    case "msp_tier3":
      return ["last72Hours", "last144Hours", "last216Hours"]

    default:
      return []
  }
}

function getSubscriptions(organizations, activeOrganization) {
  // If the active organization is managed by a MSP, we'll need to find
  // the MSP's organization. The MSP's organization is the one that has
  // subscriptions and the managed organization is the beneficiary.
  // Edge case: for some distributor organizations (e.g. SW) even though
  // the active organization is managed by a MSP, the organizations returned
  // by the API might not include the MSP's organization.
  const mspOrganization = activeOrganization.managed_by_msp_id
    ? organizations.find(
        (organization) =>
          organization.owned_msp_id === activeOrganization.managed_by_msp_id,
      )
    : null

  return httpClient
    .get("/subscriptions", {
      params: mspOrganization
        ? {
            organization_id: mspOrganization.id,
            beneficiary_id: activeOrganization.id,
          }
        : {
            organization_id: activeOrganization.id,
          },
    })
    .then((res) =>
      res.data.data.filter(
        (subscription) => subscription.attributes.status !== "cancelled",
      ),
    )
}

function getSubscriptionByBeneficiary(activeOrganization) {
  // suborgs the call should be done with the beneficiary_id param
  // suborg doesn’t owns a subscription, it is a beneficiary of a subscription.

  return httpClient
    .get("/subscriptions", {
      params: {
        beneficiary_id: activeOrganization.id,
      },
    })
    .then((res) =>
      res.data.data.filter(
        (subscription) => subscription.attributes.status !== "cancelled",
      ),
    )
}

// In theory all subscriptions have beneficiaries, but in practice they don't.
// So we'll first try to find the subscription by looking at the beneficiaries
// array, and if not found, we'll use a fallback.
function getActiveSubscription(activeOrganization, subscriptions) {
  const beneficiaryOfSubscription = subscriptions.find((subscription) =>
    // `beneficiaries` doesn't exist for read only users
    subscription.attributes.beneficiaries?.find(
      (beneficiary) => Number(beneficiary.id) === Number(activeOrganization.id),
    ),
  )

  if (beneficiaryOfSubscription) {
    return beneficiaryOfSubscription
  }

  return subscriptions
    .filter(
      (subscription) =>
        !subscription.attributes.beneficiaries ||
        subscription.attributes.beneficiaries.length === 0,
    )
    .find(
      (subscription) =>
        // `organization_id` doesn't exist for read only users so we'll fallback to `relationships.organization`
        Number(
          subscription.attributes.organization_id ??
            subscription.relationships.organization.data.id,
        ) === Number(activeOrganization.id),
    )
}

function getPlan(
  activeOrganization,
  featureFlags,
  activeSubscription,
  subscriptionByBeneficiary,
) {
  if (featureFlags.isLegacyEnterprise) {
    return "enterprise"
  }

  if (activeSubscription) {
    return activeSubscription.attributes.plan
  }

  if (
    subscriptionByBeneficiary &&
    !!localStorage.getItem("organization.isMSPSubAccount")
  ) {
    return subscriptionByBeneficiary.plan.replace("legacy_", "")
  }

  if (activeOrganization.trial_days) {
    return "trial"
  }

  return "legacy"
}

// 1. Returns itself if the active organization is MSP or a regular
//    organization
// 2. Returns the MSP if the active organization is a suborganization and the
//    logged user is member of a MSP
// 3. Returns itself if the active organization is a suborganization, but
//    the logged user is not member of any MSP
function getMainOrganization(activeOrganization, organizations) {
  if (!activeOrganization) {
    return null
  }

  if (activeOrganization.type === "suborganization") {
    const msp = organizations.find(
      (organization) =>
        organization.type === "msp" &&
        organization.owned_msp_id === activeOrganization.managed_by_msp_id,
    )

    // If we can find a MSP within the list of organizations, it means that
    // the logged user is member of a MSP
    if (msp) {
      return msp
    }
  }

  return activeOrganization
}

async function authenticate(organizationId) {
  try {
    const { user, organizations } = await getUserAndOrgData()

    const activeOrganization = organizations.find(
      (organization) => Number(organization.id) === Number(organizationId),
    )

    const mainOrganization = getMainOrganization(
      activeOrganization,
      organizations,
    )

    const msp = !mainOrganization
      ? null
      : mainOrganization.feature_flags.includes("msp") &&
        mainOrganization.owned_msp_id &&
        ["owner", "administrator", "super_administrator"].includes(
          activeOrganization.role,
        )
      ? await getMspData(mainOrganization.owned_msp_id)
      : null

    // Feature flags should be composed both by the active and main organizations
    const featureFlags = normalizeFeaturesFlags([
      ...activeOrganization.feature_flags,
      ...(mainOrganization?.feature_flags ?? []),
    ])

    const subscriptions = await getSubscriptions(
      organizations,
      activeOrganization,
    )

    const subscriptionByBeneficiary = await getSubscriptionByBeneficiary(
      activeOrganization,
    )

    const activeSubscription = getActiveSubscription(
      activeOrganization,
      subscriptions,
    )

    const subscriptionByBeneficiaryTransformed = subscriptionByBeneficiary[0]
      ? subscriptionSchema.process(subscriptionByBeneficiary[0])
      : null

    const plan = getPlan(
      activeOrganization,
      featureFlags,
      activeSubscription,
      subscriptionByBeneficiaryTransformed,
    )

    return {
      user: {
        ...userSchema.process(user),
        rolesByOrganization: organizations.reduce(
          (acc, organization) => ({
            ...acc,
            [organization.id]: organization.role,
          }),
          {},
        ),
        acl: getAccessControlList(plan, featureFlags),
        maxRetentionDays: getMaxRententionDays(plan, featureFlags),
        querylogOptions: getQuerylogOptions(plan, featureFlags),
        plan,
      },
      organizations: organizations.map(organizationSchema.process),
      activeOrganization: organizationSchema.process(activeOrganization),
      featureFlags,
      subscriptions: subscriptions.map(subscriptionSchema.process),
      activeSubscription: activeSubscription
        ? subscriptionSchema.process(activeSubscription)
        : null,
      subscriptionByBeneficiary: subscriptionByBeneficiaryTransformed,
      msp,
    }
  } catch (error) {
    if (window.rollbar) {
      window.rollbar.error("Failed to authenticate", error)
    }

    throw error
  }
}

export default authenticate
