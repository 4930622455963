import React from "react"
import { useHistory } from "react-router"
import { connect } from "react-redux"
import { SessionContext } from "_v2/Modules/Auth"
import createUrl from "utils/createUrl"
import { DISTPLATFORM_TEMPLATES } from "_Infinity/DistributorPlatform/constants"
import { Spinner } from "_Infinity/Core/Components/Spinner"
import { __logout } from "actions/auth"
import { hasDataExportAddOn } from "utils/dataExport"
import { isPageMspDashboard } from "utils/isPageMspDashboard"
import { webAuth } from "api/auth0/auth0"
import { getUserPicture } from "actions/auth"
import { replacePicture } from "utils/auth"

const getMessageType = (message) =>
  typeof message === "string" ? message : message.type

const getMessagePayload = (message) =>
  typeof message === "string" ? {} : message.payload

// If `REACT_APP_REPORTING_INSIGHTS_URL` is not set or empty and the domain is `dnsfilter.app` or `dnsfilter.site` we'll fallback to `<subdomain>.insights-app.dnsfilter.<app/site>` so preview links can work for both `app` and `insights-app`
const NEW_APP_URL =
  process.env.REACT_APP_REPORTING_INSIGHTS_URL ||
  (window.location.host.match(/dnsfilter.(app|cloud|site|run)$/)
    ? "https://" +
      window.location.host.replace(
        /^(.*)\.dnsfilter\.([a-z]+)$/,
        "$1.insights-app.dnsfilter.$2",
      )
    : null)

const NAVBAR_HEIGHT = "66px"
const FOOTER_HEIGHT = "40px"

const NewContainer = (props) => {
  const history = useHistory()

  const [iframeLoading, setIframeLoading] = React.useState(true)

  const session = React.useContext(SessionContext)

  const showDataExportByAddons = hasDataExportAddOn(session)

  const userPlanRef = React.useRef(session.user.plan)
  const maxRetentionDaysRef = React.useRef(session.user?.maxRetentionDays)
  const mainOrganizationRef = React.useRef(session.getMainOrganization)
  const isAppAwareBetaRef = React.useRef(session.featureFlags?.isAppAwareBeta)
  const showDataExportByAddonsRef = React.useRef(showDataExportByAddons)
  const onMessageRef = React.useRef(props.onMessage)
  const fullNameRef = React.useRef(session.user?.fullName)
  const updateUserPictureRef = React.useRef(props.updateUserPicture)
  const isActiveOrganizationDistributorRef = React.useRef(
    session.activeOrganization?.isDistributor,
  )
  const showMobileRoamingClientRef = React.useRef(
    session?.user?.acl.deploymentsRoamingClientsInstallMobile,
  )

  React.useEffect(() => {
    userPlanRef.current = session.user.plan
    maxRetentionDaysRef.current = session.user?.maxRetentionDays
    mainOrganizationRef.current = session.getMainOrganization
    isAppAwareBetaRef.current = session.featureFlags?.isAppAwareBeta
    showDataExportByAddonsRef.current = showDataExportByAddons
    onMessageRef.current = props.onMessage
    fullNameRef.current = session.user?.fullName
    updateUserPictureRef.current = props.updateUserPicture
    isActiveOrganizationDistributorRef.current =
      session.activeOrganization?.isDistributor
    showMobileRoamingClientRef.current =
      session?.user?.acl.deploymentsRoamingClientsInstallMobile
  })

  const iframeRef = React.useRef(null)

  const sendToken = React.useCallback(() => {
    if (!iframeRef.current) {
      return
    }

    const userType = localStorage.getItem("organization.isStandardOrg")
      ? "organization"
      : localStorage.getItem("organization.isMSPSubAccount")
      ? "suborganization"
      : "msp"
    const organizationId =
      userType === "msp"
        ? localStorage.getItem("owned_msp_id")
          ? null // User is viewing a MSP org
          : localStorage.getItem("orgId")
        : localStorage.getItem("orgId")

    const isMspDashboard = isPageMspDashboard(
      mainOrganizationRef.current(),
      window.location.pathname,
    )

    iframeRef.current.contentWindow.postMessage(
      {
        distplatform: localStorage.getItem("distributor")
          ? JSON.stringify(
              DISTPLATFORM_TEMPLATES[localStorage.getItem("distributor")],
            )
          : null,
        token: localStorage.getItem("access_token"),
        useMfa: localStorage.getItem("use_mfa"),
        mfaToken: localStorage.getItem("mfa_token"),
        userFullName: fullNameRef.current,
        userType,
        userPlan: userPlanRef.current,
        organizationId,
        maxRetentionDays: Number(maxRetentionDaysRef.current ?? 30),
        ownedMspId: localStorage.getItem("owned_msp_id"),
        mainOrganizationId: Number(mainOrganizationRef.current()?.id ?? null),
        NEXT_PUBLIC_WHITELABEL_SEGMENT:
          process.env.REACT_APP_WHITELABEL_SEGMENT,
        NEXT_PUBLIC_SEGMENT_ID: process.env.REACT_APP_SEGMENT_ID,
        isAppAwareBeta: isAppAwareBetaRef.current ?? false,
        showDataExportByAddons: showDataExportByAddonsRef.current ?? false,
        locationOrigin: window.location.origin,
        locationPathname: window.location.pathname,
        isMspDashboard,
        isActiveOrganizationDistributor:
          isActiveOrganizationDistributorRef.current,
        showMobileRoamingClient: showMobileRoamingClientRef.current,
        whitelabelName: localStorage.getItem("whitelabel.name"),
      },
      NEW_APP_URL,
    )
  }, [])

  React.useEffect(() => {
    const interval = setInterval(sendToken, 30_000)
    return () => clearInterval(interval)
  }, [sendToken])

  React.useEffect(() => {
    function handleMessage(e) {
      if (e.origin !== NEW_APP_URL) {
        return
      }

      const type = getMessageType(e.data)
      const payload = getMessagePayload(e.data)

      onMessageRef.current?.(type, e.data)

      if (type === "GO_TO_ACCOUNT_MFA") {
        return history.push("/account/two_factor_authentication/intro")
      }

      if (type === "REMOVE_MFA") {
        localStorage.removeItem("use_mfa")
        localStorage.removeItem("mfa_token")
        return
      }

      if (type === "GO_TO_MFA_SETTINGS") {
        return history.push(
          createUrl("/settings/settings/two-factor-authentication"),
        )
      }

      if (type === "GO_TO_GDPR_SETTINGS") {
        return history.push(createUrl("/settings/settings/gdpr"))
      }

      if (type === "GO_TO_RC_SETTINGS") {
        return history.push(createUrl("/settings/settings/roaming-clients"))
      }

      if (type === "GO_TO_USER_MANAGEMENT") {
        return history.push(createUrl("/settings/users"))
      }

      if (type === "GO_TO_DATA_EXPLORER_BY_ORGANIZATION") {
        return history.push(
          createUrl(
            "/reporting/data-explorer?keywords=&drilldowns%5B0%5D%5BbreakdownBy%5D=organization&timeframe=last30Days",
          ),
        )
      }

      if (type === "GO_TO_DATA_EXPORT") {
        const isMspDashboard = isPageMspDashboard(
          mainOrganizationRef.current(),
          window.location.pathname,
        )

        if (isMspDashboard) {
          //When there is a change in the URL from MSP to ORG, we need to remove the owned_msp_id for the change to work
          localStorage.removeItem("owned_msp_id")
          window.location.href = `/organizations/${
            mainOrganizationRef.current()?.id
          }/tools/data-export`
          return
        }
        return history.push(createUrl("/tools/data-export"))
      }

      if (type === "GO_TO_SYNC_TOOLS") {
        const isMspDashboard = isPageMspDashboard(
          mainOrganizationRef.current(),
          window.location.pathname,
        )

        if (isMspDashboard) {
          //When there is a change in the URL from MSP to ORG, we need to remove the owned_msp_id for the change to work
          localStorage.removeItem("owned_msp_id")
          window.location.href = `/organizations/${
            mainOrganizationRef.current()?.id
          }/deployments/sync-tools`
          return
        }
        return history.push(createUrl("/deployments/sync-tools"))
      }

      if (type === "GO_TO_SSO") {
        return history.push(createUrl("/settings/settings/single-sign-on"))
      }

      if (type === "GO_TO_BILLING") {
        window.location.href = `/organizations/${
          mainOrganizationRef.current()?.id
        }/settings/billing-info`
        return
      }

      if (type === "GO_TO_SUBSCRIPTION") {
        window.location.href = `/organizations/${
          mainOrganizationRef.current()?.id
        }/settings/billing-info/subscription`
        return
      }

      if (type === "RELOAD_PAGE") {
        return window.location.reload()
      }

      if (type === "LOG_OUT") {
        __logout()
        return window.location.reload()
      }

      if (type === "CONNECT_GOOGLE") {
        sessionStorage.setItem(
          "previous_access_token",
          localStorage.getItem("access_token"),
        )
        webAuth.authorize({
          connection: "google-oauth2",
          prompt: "select_account",
          login_hint: null,
        })
      }

      if (type === "GO_TO_FEATURE_REQUEST") {
        window.location.href = `/organizations/${
          mainOrganizationRef.current()?.id
        }/feature-request`
        return
      }

      if (type === "GO_TO_OVERVIEW") {
        window.location.href = "/"
        return
      }

      if (type === "SET_SUB_FORM_IS_DIRTY") {
        localStorage.setItem("subFormIsDirty", "true")
        window.dispatchEvent(new Event("storage"))
      }

      if (type === "REMOVE_SUB_FORM_IS_DIRTY") {
        localStorage.removeItem("subFormIsDirty")
        window.dispatchEvent(new Event("storage"))
      }

      if (type.startsWith("GO_TO_ORG_PROFILE_")) {
        const orgId = type.split("GO_TO_ORG_PROFILE_")[1]
        //When there is a change in the URL from MSP to ORG or ORG to MSP, we need to remove the owned_msp_id for the change to work
        localStorage.removeItem("owned_msp_id")
        window.location.href = `/organizations/${orgId}/settings/profile`
        return
      }

      if (type === "UPDATE_USER_PICTURE") {
        const userPicture = replacePicture(
          payload.firstInitial,
          payload.lastInitial,
        )
        localStorage.setItem("userPicture", userPicture)
        updateUserPictureRef.current(userPicture)
        return
      }
    }

    window.addEventListener("message", handleMessage, false)

    return () => window.removeEventListener("message", handleMessage, false)
  }, [history, sendToken])

  if (!NEW_APP_URL) {
    return null
  }

  return (
    <div
      className={props.className}
      style={
        props.asModal
          ? {
              backgroundColor: "rgba(3, 22, 37, 0.5)",
              position: "fixed",
              inset: 0,
              zIndex: 50,
            }
          : {
              position: "relative",
              ...(props.fillContainer && { width: "100%", height: "100%" }),
            }
      }
    >
      <iframe
        ref={iframeRef}
        title={props.title}
        src={NEW_APP_URL + (props.path ?? "/")}
        width="100%"
        style={{
          display: "block",
          border: "none",
          ...(props.asModal || props.fillContainer
            ? {
                height: "100%",
              }
            : {
                borderBottom: "1px solid #e5e7eb",
                height: `calc(100vh - ${NAVBAR_HEIGHT} - ${FOOTER_HEIGHT})`,
              }),
        }}
        allow="clipboard-read; clipboard-write"
        onLoad={() => {
          sendToken()
          setIframeLoading(false)
        }}
        onError={() => {
          setIframeLoading(false)
        }}
      />
      {iframeLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner size={4} />
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateUserPicture: (picture) => dispatch(getUserPicture(picture)),
})

export const NewAppContainer = connect(null, mapDispatchToProps)(NewContainer)
