import React, { useEffect } from "react"
import { sendRequest } from "_Infinity/Core/Utils/sendRequest"

import useSession from "../Hooks/useSession"

import SessionContext from "../Contexts/SessionContext"

import Content from "components/Content"
import Card from "components/Card"
import Button from "components/Button"
import Spinner from "_v2/Core/Components/Spinner"

import { __logout } from "actions/auth"

function SessionProvider(props) {
  let session = useSession()

  // If the user has multiple accounts with the same email and he didn't select an account yet we need to redirect him to the select account page
  useEffect(() => {
    if (!props.ready || localStorage.getItem("selected_account")) {
      return
    }

    console.group()

    console.log("Dispatching multiple accounts check")

    sendRequest({
      method: "post",
      path: `/authenticate?limit_organizations=1&skip_user_checks=false`,
    })
      .then((data) => {
        console.log("Couldn't find multiple accounts, data returned:", data)
      })
      .catch((error) => {
        if (Array.isArray(error.data)) {
          console.log("Multiple accounts found:", error.data)
          window.location.href = "/select-account"
        } else {
          console.log(
            "Couldn't find multiple accounts, error returned:",
            error.message,
          )
        }
      })

    console.groupEnd()
  }, [props.ready])

  useEffect(() => {
    if (props.ready) {
      session.sendReady(Number(localStorage.getItem("orgId")))
    } else {
      session.sendLogout()
    }
  }, [session, props.ready])

  if (session.failure) {
    return (
      <Content>
        <Card title="Oops!">
          <p>
            We had an issue while restoring your session. If the issues
            continues after a retry, we recommend logging out and logging in
            again.
          </p>

          <Button
            intent="primary"
            onClick={() => session.sendRetry()}
            style={{ marginRight: "15px" }}
          >
            Retry
          </Button>
          <Button
            onClick={() => {
              __logout()
              window.location.reload()
            }}
          >
            Log Out
          </Button>
        </Card>
      </Content>
    )
  }

  if (props.ready && session.loading) {
    return (
      <h1
        className="text-center"
        style={{
          marginTop: 20,
        }}
      >
        <Spinner size={6} />
      </h1>
    )
  }

  return (
    <SessionContext.Provider value={session}>
      {props.children}
    </SessionContext.Provider>
  )
}

export default SessionProvider
